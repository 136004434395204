<template>
  <div class="throughput">
    <head-nav></head-nav>
    <div class="throughput-banner">
      <img
        src="@/assets/image/throughput/throughput.png"
        alt=""
      />
    </div>

    <div class="throughput-box1">
      <div class="wrap">
        <h3 class="throughput-title"></h3>

        <div class="throughput-img">
          <div
            class="throughput-imgs"
            v-for="(item, index) in $t('throughput.list-top')"
            :key="index"
            @click.stop="onClickOpenTop(item.id)"
          >
            <img
              class="img-box"
              :src="item.img"
              alt=""
            />

            <transition name="nameoftransition">
              <div
                v-if="detailTopId === item.id"
                class="listEnlargeImg"
              >
                <div class="img-box">
                  <i
                    class="iconfont icon-x"
                    @click.stop="onClickCloneTop()"
                  ></i>

                  <div class="img-box-list">
                       <div
                      class="list-info"
                      v-if="detailTopId === '1'"
                    >
                      <div
                        v-for="(item,index) in $t('throughput.list-top[0].list')"
                        :key="index"
                      >
                        <!-- <h3>{{item.title}}</h3> -->
                        <div>
                          <img
                            :src="item.img"
                            alt=""
                          >
                        </div>
                      </div>

                    </div>
                    <div
                      class="list-info"
                      v-if="detailTopId === '3'"
                    >
                      <div
                        v-for="(item,index) in $t('throughput.list-top[2].list')"
                        :key="index"
                      >
                        <!-- <h3>{{item.title}}</h3> -->
                        <div>
                          <img
                            :src="item.img"
                            alt=""
                          >
                        </div>
                      </div>
                    </div>

                    <div
                      class="list-info"
                      v-if="detailTopId === '6'"
                    >
                      <div
                        v-for="(item,index) in $t('throughput.list-top[5].list')"
                        :key="index"
                      >
                        <!-- <h3>{{item.title}}</h3> -->
                        <div>
                          <img
                            :src="item.img"
                            alt=""
                          >
                        </div>
                      </div>
                    </div>

                    <div
                      class="list-info"
                      v-if="detailTopId === '8'"
                    >
                      <div
                        v-for="(item,index) in $t('throughput.list-top[7].list')"
                        :key="index"
                      >
                        <!-- <h3>{{item.title}}</h3> -->
                        <div>
                          <img
                            :src="item.img"
                            alt=""
                          >
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </transition>

            <div
              class="listEnlargeImg-mask"
              :style="detailTopId !== 0 ?'opacity: 1;z-index:9;':''"
            ></div>

          </div>
        </div>
      </div>
    </div>

    <div class="throughput-box2">
      <div class="wrap">
        <div class="throughput-img">
          <div
            class="throughput-imgs"
            v-for="(item, index) in $t('throughput.list-bottom')"
            :key="index"
            @click.stop="onClickOpenBelow(item.id)"
          >
            <img
              class="throughput-imgs-list"
              :src="item.img"
              :alt="item.id"
            />
            <transition name="nameoftransition">
              <div
                v-if="detailBelowId === item.id"
                class="listEnlargeImg"
              > <i
                  class="iconfont icon-cha"
                  @click.stop="onClickCloneBelow()"
                ></i>

                <div class="list-img">
                  <h3>{{item.title}}</h3>

                  <img
                    :src="item.img"
                    :alt="item.title"
                  >

                  <h3>{{item.title}}</h3>

                  <img
                    :src="item.img"
                    :alt="item.title"
                  >

                  <h3>{{item.title}}</h3>

                  <img
                    :src="item.img"
                    :alt="item.title"
                  >

                </div>

              </div>
            </transition>

            <div
              class="listEnlargeImg-mask"
              :style="isMaskShow?'opacity: 1;z-index:9;':''"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import HeadNav from './head-nav';

import Footers from './footers';

export default {
  title: 'Change direction',
  components: {
    HeadNav,
    Footers,
  },
  apollo: {
    throughput1: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              throughput1: allAboveProductionCapacities {
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        return gql`
          query {
            throughput1: allEnAboveProductionCapacities {
              image {
                publicUrl
              }
            }
          }
        `;
      },
    },

    throughput2: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              throughput2: allBelowProductionCapacities {
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        return gql`
          query {
            throughput2: allEnBelowProductionCapacities {
              image {
                publicUrl
              }
            }
          }
        `;
      },
    },
  },

  data() {
    return {
      detailTopId: 0,
      detailBelowId: 0,
      isMaskShow: false,
      throughput1: [],
      throughput2: [],
    };
  },
  methods: {
    onClickOpenTop(ID) {
      if (ID === '1' || ID === '3' || ID === '6' || ID === '8') {
        this.detailTopId = ID;
      }
    },
    onClickCloneTop() {
      this.detailTopId = 0;
    },
    onClickOpenBelow(ID) {
      if (ID === '1' || ID === '4') {
        this.detailBelowId = ID;
        this.isMaskShow = true;
      }
    },
    onClickCloneBelow() {
      this.detailBelowId = 0;
      this.isMaskShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.nameoftransition-enter-active,
.nameoftransition-leave-active {
  opacity: 1;
  transform: scale(0);
}
.nameoftransition-enter,
.nameoftransition-leave-to {
  opacity: 0;
}
.throughput {
  .throughput-banner {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  .throughput-box1 {
    width: 100%;
    height: 500px;
    background: #eee;

    .wrap {
      padding: 10px 0;

      .throughput-title {
        text-align: center;
      }

      .throughput-img {
        margin-top: 20px;
        position: relative;

        .throughput-imgs {
          position: absolute;
          background: #666666;
          margin: 45px auto;
          color: #fafafa;
          line-height: 24px;

          img {
            width: 100%;
            height: 100%;
          }
          .listEnlargeImg-mask {
            cursor: default;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            opacity: 0;
            z-index: -1;
          }
          .listEnlargeImg {
            cursor: default;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 9;
            width: 100%;
            height: 100%;
            z-index: 10;
            transition: all 0.5s;

            .img-box {
              position: absolute;
              top: 48%;
              left: 50%;
              transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              -o-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -khtml-transform: translate(-50%, -50%);
              z-index: 20;
              width: 60%;
              height: 600px;

              &-list {
                background: #fff;
                border: 10px solid #fff;
                overflow: auto;
                padding: 10px;
                height: 600px;
                border-radius: 2px;
                color: #000;

                &::-webkit-scrollbar-track-piece {
                  display: none;
                  // background-color: #f8f8f8;
                }
                &::-webkit-scrollbar {
                  display: none;
                  // width: 12px;
                  // height: 12px;
                }
                &::-webkit-scrollbar-thumb {
                  display: none;
                  // background-color: #ddd;
                  // background-clip: padding-box;
                  // min-height: 28px;
                  // border-radius: 20px;
                  // height: 9px;
                }
                &::-webkit-scrollbar-thumb:hover {
                  display: none;
                  // background-color: #ccc;
                }

                // h3 {
                //   text-align: center;
                // }

                img {
                  width: 100%;
                  height: auto;
                  // margin: 10px 0;
                }
              }
            }

            .icon-x {
              position: absolute;
              top: 30px;
              right: 30px;
              z-index: 999;
              color: #ffbf00;
              font-size: 40px;
              cursor: pointer;
            }
          }

          .mask {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: #000;
            opacity: 0.7;
            text-align: center;
            height: 40px;
            line-height: 40px;
          }
        }

        .throughput-imgs {
          width: 300px;
          height: 180px;
        }

        .throughput-imgs:nth-child(1) {
          top: 0;
          left: 0;
        }

        .throughput-imgs:nth-child(2) {
          top: 0;
          left: 300px;
        }

        .throughput-imgs:nth-child(3) {
          top: 0;
          left: 600px;
        }
        .throughput-imgs:nth-child(4) {
          top: 0;
          left: 900px;
        }

        .throughput-imgs:nth-child(5) {
          top: 180px;
          left: 0;
        }

        .throughput-imgs:nth-child(6) {
          top: 180px;
          left: 300px;
        }

        .throughput-imgs:nth-child(7) {
          top: 180px;
          left: 600px;
        }

        .throughput-imgs:nth-child(8) {
          top: 180px;
          left: 900px;
        }

        .throughput-imgs:nth-child(1):hover,
        .throughput-imgs:nth-child(3):hover,
        .throughput-imgs:nth-child(6):hover,
        .throughput-imgs:nth-child(8):hover {
          cursor: pointer;
          &:before {
            content: '';
            position: absolute;
            left: 0px;
            top: 0px;
            width: 300px;
            height: 180px;
            background-color: rgba(255, 255, 255, 0.3);
            z-index: 2;
          }
        }
      }
    }
  }

  .throughput-box2 {
    .wrap {
      padding: 100px 0;

      .throughput-title {
        text-align: center;
      }

      .throughput-img {
        margin-top: 50px;
        position: relative;
        height: 300px;

        .throughput-imgs {
          width: 300px;
          height: 300px;
          position: absolute;

          &-list {
            width: 100%;
            height: 100%;
          }

          .listEnlargeImg-mask {
            display: none;
            cursor: default;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            opacity: 0;
            z-index: -1;
          }

          .listEnlargeImg {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            transition: all 0.4s;
            &::-webkit-scrollbar {
              width: 12px;
              height: 12px;
            }

            .list-img {
              position: absolute;
              margin-left: auto;
              max-width: 1100px;
              border-radius: 3px;
              background: white;
              box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px;
              top: 72px;
              left: 72px;
              right: 72px;
              margin-right: auto;
              height: calc(100% - 144px);
              overflow: auto;
              padding-bottom: 40px;

              &::-webkit-scrollbar-track-piece {
                background-color: #f8f8f8;
              }
              &::-webkit-scrollbar {
                width: 14px;
                height: 12px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #ddd;
                background-clip: padding-box;
                min-height: 28px;
                border-radius: 20px;
                height: 9px;
              }
              &::-webkit-scrollbar-thumb:hover {
                background-color: #ccc;
              }

              h3 {
                text-align: center;
                margin: 10px 0;
              }

              img {
                width: 100%;
                height: auto;
              }
            }

            .icon-cha {
              position: absolute;
              left: 12%;
              top: 5%;
              line-height: 40px;
              color: #fafafa;
              font-size: 24px;
            }
          }

          &:nth-child(1) {
            width: 450px;
            height: 300px;
            top: 0;
            left: 0;
          }
          &:nth-child(2) {
            width: 300px;
            height: 150px;
            top: 0;
            left: 450px;
          }

          &:nth-child(3) {
            width: 300px;
            height: 150px;
            top: 150px;
            left: 450px;
          }
          &:nth-child(4) {
            width: 450px;
            height: 300px;
            top: 0;
            left: 750px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .throughput .throughput-banner,
  .throughput .throughput-box1,
  .throughput .throughput-box2 {
    width: 1200px;
  }
  .throughput .throughput-box1 .wrap .throughput-img .throughput-imgs {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
